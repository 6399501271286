import request from '@/utils/request'

//新增支付方式
export function addPayment(data) {
  return request({
    url: 'crm/tPayType',
    method: 'post',
    data,
  })
}

//编辑支付方式
export function editPayment(data) {
  return request({
    url: 'crm/tPayType',
    method: 'put',
    data,
  })
}

// 禁用或启用
export function disableOrStartUsePaymentManage(data) {
  return request({
    url: `crm/tPayType/switchStatus/${data.id}`,
    method: 'PUT',
  })
}

// 支付方式列表
export function getPaymentList(data) {
  return request({
    url: 'crm/tPayType',
    method: 'get',
    params: data,
  })
}
